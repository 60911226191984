import { Group, Select, Text, SelectProps } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { fetchPatients } from '../../utils/CustomAPI';
import { ResourceAvatar, useMedplum } from '@medplum/react';
import { useForm } from '@mantine/form';
import '../AIAssistant/AutoCompleteSearch.css';

interface AutoCompleteSearchProps {
  setPatients: (patients: { label: string; value: string; dob?: string }[]) => void;
  setSelectedPatient: (item: string) => void;
  selectedPatient: string;
  patients: { label: string; value: string; dob?: string }[];
  searchInputRef: React.RefObject<HTMLInputElement>;
}

interface FormValues {
  name: string;
  dob: Date | null;
}

interface ItemProps extends SelectProps {
  value: string;
  label: string;
  dob?: string;
}

const defaultQuery = (searchTerm: string): string => `{
  PatientList(name: "${searchTerm}", _count: 1000) {
    resourceType
    id
    birthDate
    name {
      family
      given
    }
  }
}`;

const getPatientQuery = (patientId: string): string => `{
  Patient(id: "${patientId}") {
    name {
      family
      given
    }
  }
}`;

const AutoCompleteSearch = ({
  setPatients,
  setSelectedPatient,
  selectedPatient,
  patients,
  searchInputRef,
}: AutoCompleteSearchProps): JSX.Element => {
  const medplum = useMedplum();
  const [data, setData] = useState<ItemProps[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const form = useForm<FormValues>({
    initialValues: {
      name: '',
      dob: null,
    },
  });

  useEffect(() => {
    if (patients) {
      const mappedData = patients?.map((patient) => ({
        value: patient.value,
        label: patient.label,
        dob: patient?.dob,
      }));
      setData(mappedData);
    }
  }, [patients]);

  useEffect(() => {
    if (searchTerm) {
      const query = defaultQuery(searchTerm);
      fetchPatients(medplum, query)
        .then((response) => {
          const patientList = response?.data?.PatientList.map(
            (patient: {
              name: {
                given: string[];
                family: string;
              }[];
              id: string;
              birthDate: string;
            }) => {
              const givenName = patient?.name?.[0]?.given ? patient?.name?.[0]?.given.join(' ') : '';
              const familyName = patient?.name?.[0]?.family || '';
              const label = familyName ? `${givenName} ${familyName}` : givenName;

              return {
                value: patient.id,
                label: label,
                dob: patient.birthDate,
              };
            }
          );
          setPatients(patientList);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    } else {
      setPatients([]);
    }
  }, [searchTerm, medplum, setPatients]);

  useEffect(() => {
    if (selectedPatient) {
      const patient = patients.find((p) => p.value === selectedPatient);
      if (patient) {
        form.setValues({
          name: patient.label,
          dob: patient.dob ? new Date(patient.dob) : null,
        });
        setSearchTerm(patient.label);
      } else {
        const query = getPatientQuery(selectedPatient);
        fetchPatients(medplum, query)
          .then((response) => {
            const patient = response?.data?.Patient;
            const givenName = patient?.name?.[0]?.given ? patient?.name?.[0]?.given.join(' ') : '';
            const familyName = patient?.name?.[0]?.family || '';
            const label = familyName ? `${givenName} ${familyName}` : givenName;
            form.setValues({
              name: label,
            });
            setSearchTerm(label);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }, [selectedPatient, patients]);

  const renderSelectOption: SelectProps['renderOption'] = ({ option }) => (
    <div>
      <Group>
        <ResourceAvatar />
        <div>
          <Text>{option.label}</Text>
          <Text size="xs">{(option as ItemProps).dob}</Text>
        </div>
      </Group>
    </div>
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Select
        ref={searchInputRef}
        placeholder="Patient"
        data={data}
        radius="8px"
        value={selectedPatient}
        className="custom-select-input"
        styles={{
          section: {
            width: 'fit-content',
            height: '100%',
            marginRight: `${!selectedPatient ? '10px' : ''}`,
          },
          input: {
            width: `${selectedPatient ? '20vw' : ''}`,
            borderRadius: '8px',
            height: '40px',
            cursor: 'not-allowed',
            pointerEvents: 'none',
            position: 'relative',
          },
        }}
        searchValue={searchTerm}
        searchable={false}
        onChange={(value: string | null) => {
          if (value) {
            setSelectedPatient(value);
            const selectedItem = data?.find((item) => item?.value === value);
            if (selectedItem) {
              setSearchTerm(selectedItem.label);
            }
          } else {
            setSelectedPatient('');
            setSearchTerm('');
            setPatients([]);
          }
        }}
        clearable={false}
        renderOption={renderSelectOption}
      />
    </div>
  );
};

export default AutoCompleteSearch;
