import { Status } from "./types";

export const USA_STATES = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
]

export const USA_TIMEZONES = [
    { value: 'Pacific/Honolulu', label: 'Hawaii Time' },
    { value: 'America/Anchorage', label: 'Alaska Time' },
    { value: 'America/Los_Angeles', label: 'Pacific Time' },
    { value: 'America/Denver', label: 'Mountain Time' },
    { value: 'America/Chicago', label: 'Central Time' },
    { value: 'America/New_York', label: 'Eastern Time' },
];

export const HealthOverview = {
    HEARTRATE: 'Heart rate',
    STEPCOUNT: 'Step count',
    BLOODPRESSURE: 'Blood pressure',
    WEIGHT: 'Weight',
    BODYWEIGHT: 'Body weight',
};

export const resources = [
    { value: 'MedicationRequest', label: 'Medications' },
    { value: 'Appointment', label: 'Appointments' },
    { value: 'Encounter', label: 'Encounters' },
    { value: 'Condition', label: 'Conditions' },
    { value: 'Immunization', label: 'Immunizations' },
    { value: 'DiagnosticReport', label: 'Diagnostic Reports' },
    { value: 'Observation', label: 'Observations' },
    { value: 'Procedure', label: 'Procedures' },
    { value: 'AllergyIntolerance', label: 'Allergies' },
    { value: 'CareTeam', label: 'Care Teams' },
    { value: 'CarePlan', label: 'Care Plans' },
    { value: 'FamilyMemberHistory', label: 'Family Member History' },
    { value: 'Coverage', label: 'Coverage' },
  ];

  export const STATUS_COLORS: Record<Status, string> = {
    accepted: '#32CD32',
    active: '#17B26A',
    amended: '#4BE4CF',
    arrived: '#4BE4CF',
    balanced: '#32CD32',
    booked: '#D41AB4',
    cancelled: '#DFE44B',
    'checked-in': '#84F384',
    completed: '#84F384',
    current: '#4BE4CF',
    draft: '#FFA500',
    'entered-in-error': '#FF6347',
    failed: '#D44B1A',
    final: '#D41AB4',
    fulfilled: '#32CD32',
    inactive: '#A9A9A9',
    incomplete: '#D44B1A',
    'in-progress': '#1A72D4',
    issued: '#D41AB4',
    noshow: '#D44B1A',
    'not-done': '#D44B1A',
    'on-hold': '#F1AB18',
    pending: '#F1AB18',
    preliminary: '#F1AB18',
    preparation: '#F1AB18',
    proposed: '#87CEEB',
    ready: '#44EFBF',
    received: '#44EFE9',
    rejected: '#FF4500',
    requested: '#EF44D1',
    stopped: '#D44B1A',
    superseded: '#D41AB4',
    unknown: '#A9A9A9',
    waitlist: '#F1AB18',
    retired: '#4d88D6',
  } as const;

  export const AssessmentTab = [
    { value: '', label: 'All' },
    { value: 'active', label: 'New' },
    { value: 'retired', label: 'Completed' },
  ]

  export const AppointmentTab = [
    { value: '', label: 'All' },
    { value: 'pending', label: 'Upcoming' },
    { value: 'booked', label: 'Completed' },
    { value: 'cancelled', label: 'Cancelled' },
  ]

  export const PragmaconnectOrgBaseUrl = 'https://pragmaconnect.s3.us-west-1.amazonaws.com/pragmaconnect_organizations.json';

  export const MywellnessOrgBaseUrl = 'https://pragmaconnect.s3.us-west-1.amazonaws.com/organizations.json';

  export const menus = [
    {
      title: '',
      links: [
        { name: 'Dashboard', target: '/dashboard' },
        { name: 'Appointments', target: '/Appointment' },
        { name: 'Assessments', target: '/Questionnaire' },
        { name: 'AI Assistant', target: '/ai-assistant' },
        { name: 'Messages', target: '#' },
        { name: 'Telehealth', target: '#' },
        { name: 'LabTests', target: '#' },
        { name: 'Invoices', target: '#' },
        { name: 'Notes', target: '#' },
      ],
    },
  ];

