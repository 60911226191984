/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { AreaChart, Area, Tooltip, ResponsiveContainer, XAxis } from 'recharts';
import { DataPoint } from '../../interfaces/DataPoint';
import { Skeleton } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { useEffect, useState } from 'react';
import { getWeight } from '../../utils/CustomAPI';
import { IconArrowDown, IconArrowUp } from '@tabler/icons-react';
import { formatDate } from '../../utils/util';

const Weight = () => {
  const medplum = useMedplum();
  const [weightData, setWeightData] = useState<DataPoint[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [weightAvg, setWeightAvg] = useState<number>(0);
  const [percentageChange, setPercentageChange] = useState<string>('0.00');
  const [latestWeight, setLatestWeight] = useState<number>(0);

  // Get weight data
  useEffect(() => {
    setIsLoading(true);
    const today = new Date().toISOString().split('T')[0];
    getWeight(medplum, medplum.getProfile()?.id || '').then((res) => {
      setIsLoading(false);
      const filteredData = res?.data.entry.filter((entry: any) => {
        const code = entry.resource.code.coding[0].display;
        return code === 'Weight' || code === 'Body weight';
      });
      const todaysData = filteredData?.filter((entry: any) => {
        const effectiveDate = entry.resource.effectiveDateTime.split('T')[0];
        return effectiveDate === today;
      });
      setLatestWeight(todaysData?.[0]?.resource.valueQuantity?.value || 0);
      setWeightData(filteredData.map((entry: any) => ({
        value: entry.resource.valueQuantity?.value,
        unit: entry.resource.valueQuantity?.unit.toString(),
        date: formatDate(entry.resource.effectiveDateTime),
      })));
      const weights = filteredData.slice(1).map((entry: any) => entry.resource.valueQuantity?.value);
      const sum = weights.reduce((a: any, b: any) => a + b, 0);
      const avg: any = ((sum / weights.length) || 0).toFixed(2);
      setWeightAvg(Number(avg));
      const percentageChange = avg && avg !== 0
        ? (Math.abs(avg - filteredData?.[0]?.resource?.valueQuantity?.value) / avg * 100).toFixed(2)
        : '0.00';
      const finalPercentageChange = (isNaN(Number(percentageChange)) || percentageChange === '0.00')
        ? '0.00'
        : percentageChange;

      setPercentageChange(finalPercentageChange);
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  return (
    <div className="tw-mt-6 xs:tw-mt-2 tw-px-5 tw-py-4 tw-block tw-w-[50%] tw-h-fit tw-bg-white tw-border border-[#F2F2F2] tw-rounded-[12px] featured-card">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-1">
        <h5 className="tw-text-[16px] tw-font-semibold tw-tracking-tight tw-text-[#101828] tw-pb-0">
          Weight
        </h5>
      </div>
      {!isLoading ? (
        <div className="tw-flex tw-justify-between tw-mt-5">
          <div className='tw-grid tw-w-[50%]'>
            <span className="tw-font-semibold tw-text-[#101828] tw-text-[22px] tw-mb-3">{latestWeight || 0} kg</span>
            <span className='tw-text-[#067647] tw-flex tw-items-center tw-font-medium' style={{
              color: weightAvg > weightData?.[0]?.value || weightAvg === 0.00 ? '#17B26A' : '#FF5C58',
            }}>
              {weightAvg > weightData?.[0]?.value || weightAvg === 0.00 ? <IconArrowUp size={15} color='#17B26A'/> : <IconArrowDown size={15} color='#FF5C58'/>}
              {percentageChange}%
              <span className='tw-ml-1 tw-text-[#475467] tw-font-medium'>vs last month</span>
            </span>
          </div>
          <div className='tw-w-[50%]'>
            <ResponsiveContainer>
              <AreaChart data={weightData} barSize={5}>
                <defs>
                  <linearGradient id="colorUv4" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={weightAvg > weightData?.[0]?.value || weightAvg === 0.00 ? "#17B26A" : '#FF5C58'} stopOpacity={0.8}/>
                    <stop offset="95%" stopColor={weightAvg > weightData?.[0]?.value || weightAvg === 0.00 ? "#17B26A" : '#FF5C58'} stopOpacity={0}/>
                  </linearGradient>
                </defs>
                <XAxis dataKey="date" tickLine={false} display='none'/>
                <Tooltip cursor={false} />
                <Area
                  dataKey="value"
                  fill="url(#colorUv4)"
                  stroke={weightAvg > weightData?.[0]?.value || weightAvg === 0.00 ? "#17B26A" : '#FF5C58'}
                  strokeWidth={2}
                  name='Weight'
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <div className='tw-grid tw-text-center tw-mt-[20px] tw-block tw-m-auto'>
          <Skeleton height={8} mb="xs" />
          <Skeleton height={8} mb="xs" />
          <Skeleton height={8} mb="xs" />
        </div>
      )}
    </div>
  );
};

export default Weight;
