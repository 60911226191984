/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Box, Notification, Text, Tooltip } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import UpcomingAppointments from './UpcomingAppointments';
import NotificationCard from './NotificationCard';
import { useMedplum } from '@medplum/react';
import FeaturedCards from './Cards/FeaturedCards';
import { Header } from '../components/Header';
import AIInsights from './AIInsights/AIInsights';
import Records from './Records/index';
import PatientMedicalHistory from './MedicalHistory/index';
import { IconDownload, IconMessageReport } from '@tabler/icons-react';
import MedicalRecord from './MedicalRecord';
import { fetchPatientDetails, getConditionList, markPatientLatestCondtion } from '../utils/CustomAPI';
import { useBrandSettings } from '../BrandContext';
import AddCondition from './AddCondition';
import { capitalize } from '@medplum/core';

export interface PatientProps {
  id: string;
  name: any;
  address: any;
  telecom: any;
  gender: string;
  birthDate: string;
  identifier: any;
}

const Index = () => {
  const medplum = useMedplum();
  const brandDetails = useBrandSettings();
  const [openedMedicalRecord, setOpenedMedicalRecord] = React.useState(false);
  const [patientData, setPatientData] = React.useState<PatientProps>();
  const [conditionNotification, setConditionNotification] = React.useState(true);
  const [isopenCondition, setIsopenCondition] = React.useState(false);
  const [isCheckPrimaryCondition, setIsCheckPrimaryCondition] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddCondition, setIsAddCondition] = useState<boolean>(false);
  const currentHour = new Date().getHours();
  const isCheckEhrPatient = localStorage.getItem('ehrPatient');
  let greeting;

  if (currentHour < 12) {
    greeting = 'Good Morning';
  } else if (currentHour < 18) {
    greeting = 'Good Afternoon';
  } else {
    greeting = 'Good Evening';
  }

  useEffect(() => {
    const graphqlQuery = `${
      '{ Patient(id: "' +
      medplum.getProfile()?.id +
      '") { resourceType id name { given family } address { line city state country } telecom {system value} gender birthDate identifier { value } } }'
    }`;

    fetchPatientDetails(medplum, graphqlQuery)
      .then((response: any) => {
        setPatientData(response?.data.Patient as PatientProps);
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
      });

    getCondition();
  }, []);

  const getCondition = async () => {
    setIsLoading(true);
    try {
      await markPatientLatestCondtion(medplum, medplum.getProfile()?.id as string);
      const response = await getConditionList(medplum, medplum.getProfile()?.id ?? '');
      setIsCheckPrimaryCondition(response?.data?.entry?.length > 0);
    } catch (error) {
      console.error('Error during API calls:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header />
      {!isLoading && isCheckEhrPatient !== 'true' && conditionNotification && !isCheckPrimaryCondition && (
        <Box
          px="lg"
          pb="sm"
          className="patient_notification tw-rounded-lg"
          style={{ marginLeft: '8px', marginRight: '8px' }}
        >
          <Notification
            icon={<IconMessageReport size="1.3rem" color={brandDetails?.iconColor || '#9552E8'} />}
            color="white"
            title={
              <Text fw={600} c="#344054" style={{ fontSize: '18px' }}>
                Attention Required: Condition Missing
              </Text>
            }
            style={{
              backgroundColor: brandDetails?.secondaryColor,
              color: brandDetails?.textColor,
              alignItems: 'flex-start',
            }}
            onClose={() => setConditionNotification(false)}
          >
            <Text mb={10}>
              It seems that no condition has been added or updated for the patient during registration. Please create a
              new condition to proceed further.
            </Text>
            <Text
              fw={600}
              span
              className="tw-text-[#6941C6]"
              style={{ cursor: 'pointer', fontSize: '16px', color: brandDetails?.textColor }}
              inherit
              onClick={() => {
                setIsopenCondition(true);
                setIsAddCondition(true);
              }}
            >
              Add New Condition
            </Text>
          </Notification>
        </Box>
      )}
      <Box px="lg" py="sm" className="dashboard-container tw-bg-[#fff]">
        <div className="tw-block tw-w-full tw-p-2 xs:tw-p-0">
          <div
            className="tw-bg-[#F9F5FF] tw-p-2 tw-rounded-lg tw-py-3 tw-flex tw-justify-between tw-items-center tw-opacity-[1]"
            style={{ backgroundColor: brandDetails?.secondaryColor }}
          >
            <div>
              <h5 className="tw-text-[18px] xs:tw-text-[18px] tw-font-semibold tw-tracking-tight tw-text-[#101828]">
                {greeting},{' '}
                {capitalize(medplum.getProfile()?.name?.[0].given?.[0] ?? '') +
                  ' ' +
                  capitalize(medplum.getProfile()?.name?.[0].family ?? '')}
              </h5>
              <h6 className="tw-text-[#475467] tw-text-[14px]">Have a nice day</h6>
            </div>
            <div className="">
              <Tooltip
                label={
                  <div style={{ width: 'auto', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                    <div style={{ fontSize: '12px' }}>Download Medical Record</div>
                  </div>
                }
                position="top"
                withArrow
              >
                <button type="button" onClick={() => setOpenedMedicalRecord(true)}>
                  <div
                    className="tw-bg-[#F9F5FF] tw-p-2 tw-h-[55px] tw-w-[55px] tw-rounded-full"
                    style={{
                      background: brandDetails?.secondaryColor,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <div
                      className="tw-bg-[#F4EBFF] tw-p-1.5 tw-h-[35px] tw-w-[35px] tw-rounded-full"
                      style={{
                        background: brandDetails?.primaryColor,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <IconDownload color={brandDetails?.iconColor || '#9552E8'} size={20} />
                    </div>
                  </div>
                </button>
              </Tooltip>
              <h5 className="tw-text-[#101828] tw-font-semibold">
                MRN : {patientData?.identifier ? patientData?.identifier[0]?.value : 'NA'}
              </h5>
            </div>
          </div>
          {/* <AssessmentQuestions /> */}
          <AIInsights />
          <div className="tw-flex tw-gap-5 xs:tw-gap-0 xs:tw-flex-wrap sm:tw-flex-wrap md:tw-flex-wrap tw-mb-6">
            <div className="tw-flex tw-gap-4 tw-w-full xs:tw-w-[100%] sm:tw-w-[100%] md:tw-w-[100%]">
              <UpcomingAppointments />
              <NotificationCard />
            </div>
          </div>
          <h5 className="tw-text-[18px] tw-text-[#101828] tw-font-semibold tw-mt-4">Health Overview</h5>
          <FeaturedCards />
          <Records />
          <PatientMedicalHistory />
        </div>
      </Box>
      {openedMedicalRecord && (
        <MedicalRecord opened={openedMedicalRecord} close={() => setOpenedMedicalRecord(false)} />
      )}
      {isopenCondition && (
        <AddCondition
          opened={isopenCondition}
          isAddCondition={isAddCondition}
          close={() => {
            getCondition();
            setIsopenCondition(false);
          }}
        />
      )}
    </>
  );
};

export default Index;
