/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import { Center, Container, Loader, Menu, Text, UnstyledButton } from '@mantine/core';
import { MouseEvent, memo, useEffect, useRef, useState } from 'react';
import { DEFAULT_SEARCH_COUNT, Filter, SearchRequest, formatSearchQuery } from '@medplum/core';
import { Bundle, OperationOutcome, Resource, ResourceType, SearchParameter } from '@medplum/fhirtypes';
// import { Container } from '../../../react/src/Container/Container';
import { useMedplum, useMedplumProfile } from '@medplum/react-hooks';
import { SearchFieldEditor } from '../../../react/src/SearchFieldEditor/SearchFieldEditor';
import { SearchFilterEditor } from '../../../react/src/SearchFilterEditor/SearchFilterEditor';
import { SearchFilterValueDialog } from '../../../react/src/SearchFilterValueDialog/SearchFilterValueDialog';
import { SearchPopupMenu } from '../../../react/src/SearchPopupMenu/SearchPopupMenu';
import { getFieldDefinitions } from '../../../react/src/SearchControl/SearchControlField';
import { addFilter, buildFieldNameString, renderValue, setPage } from '../../../react/src/SearchControl/SearchUtils';
import { useLocation } from 'react-router-dom';
import {
  IconAdjustmentsHorizontal,
  IconCalendar,
  IconEye,
  IconPencil,
  IconPlus,
  IconTrash,
  IconDirectionSign,
} from '@tabler/icons-react';
import Appointments from './Appointments/Appointments';
import FilterTags from '../components/FilterTags';
import SearchAndFilter from '../components/SearchAndFilter';
import { Header } from '../components/Header';
import { useBrandSettings } from '../BrandContext';
import { format } from 'date-fns';
import { StatusBadge } from '../components/StatusBadge';
import { STATUS_COLORS } from '../utils/constant';
import { ResourceWithStatus } from '../utils/types';
import AssessmentQuestionsDrawer from './Assessment/AssessmentQuestionsDrawer';

export class SearchChangeEvent extends Event {
  readonly definition: SearchRequest;

  constructor(definition: SearchRequest) {
    super('change');
    this.definition = definition;
  }
}

export class SearchLoadEvent extends Event {
  readonly response: Bundle;

  constructor(response: Bundle) {
    super('load');
    this.response = response;
  }
}

export class SearchClickEvent extends Event {
  readonly resource: Resource;
  readonly browserEvent: MouseEvent;

  constructor(resource: Resource, browserEvent: MouseEvent) {
    super('click');
    this.resource = resource;
    this.browserEvent = browserEvent;
  }
}

export interface SearchControlProps {
  readonly search: any;
  readonly checkboxesEnabled?: boolean;
  readonly hideToolbar?: boolean;
  readonly hideFilters?: boolean;
  readonly onLoad?: (e: SearchLoadEvent) => void;
  readonly onChange?: (e: SearchChangeEvent) => void;
  readonly onClick?: (e: SearchClickEvent) => void;
  readonly onNew?: () => void;
}

interface SearchControlState {
  readonly searchResponse?: Bundle;
  readonly selected: { [id: string]: boolean };
  readonly fieldEditorVisible: boolean;
  readonly filterEditorVisible: boolean;
  readonly filterDialogVisible: boolean;
  readonly exportDialogVisible: boolean;
  readonly filterDialogFilter?: Filter;
  readonly filterDialogSearchParam?: SearchParameter;
}

/**
 * The SearchControl component represents the embeddable search table control.
 * It includes the table, rows, headers, sorting, etc.
 * It does not include the field editor, filter editor, pagination buttons.
 * @param props - The SearchControl React props.
 * @returns The SearchControl React node.
 */
export function SearchControl(props: SearchControlProps): JSX.Element {
  const medplum = useMedplum();
  const brandDetails = useBrandSettings();
  const location = useLocation();
  const [outcome, setOutcome] = useState<OperationOutcome | undefined>();
  const { search, onLoad } = props;
  const [schemaLoaded, setSchemaLoaded] = useState(false);
  const [isAppointment, setIsAppointment] = useState<boolean>(false);
  const [isResourceId, setIsResourceId] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState('');
  const [isQuestionnaire, setIsQuestionnaire] = useState<boolean>(false);
  const [activeFilterTab, setActiveFilterTab] = useState<string>('');
  const profile = useMedplumProfile();
  const id = profile?.id;
  const patientId = id || '';

  const [state, setState] = useState<SearchControlState>({
    selected: {},
    fieldEditorVisible: false,
    filterEditorVisible: false,
    exportDialogVisible: false,
    filterDialogVisible: false,
  });

  const stateRef = useRef<SearchControlState>(state);
  stateRef.current = state;

  useEffect(() => {
    setOutcome(undefined);
    const filters = [
      ...(search.filters || []),
      {
        code: search.resourceType === 'Questionnaire' ? 'identifier' : 'patient',
        operator: '=',
        value: patientId,
      },
    ];
    medplum
      .search(
        search.resourceType as ResourceType,
        formatSearchQuery({
          ...search,
          total: search.total ?? 'accurate',
          fields: undefined,
          filters,
        })
      )
      .then((response) => {
        setState({ ...stateRef.current, searchResponse: response });
        if (onLoad) {
          onLoad(new SearchLoadEvent(response));
        }
        setSchemaLoaded(true);
      })
      .catch((reason) => {
        setState({ ...stateRef.current, searchResponse: undefined });
        setOutcome(reason);
      });
  }, [medplum, search, onLoad, patientId]);

  /**
   * Emits a change event to the optional change listener.
   * @param newSearch - The new search definition.
   */
  function emitSearchChange(newSearch: SearchRequest): void {
    if (props.onChange) {
      props.onChange(new SearchChangeEvent(newSearch));
    }
  }

  if (!schemaLoaded) {
    return (
      <Center style={{ width: '100%', height: '100%' }}>
        <Loader />
      </Center>
    );
  }

  const fields = getFieldDefinitions(search);
  const resourceType = search.resourceType;
  const lastResult = state.searchResponse;
  const entries = lastResult?.entry;
  const resources = entries?.map((e) => e.resource);

  fields?.map((field: any) => {
    if (field.name === 'title') {
      field['searchParams'] = [
        {
          resourceType: 'SearchParameter',
          code: 'title',
          name: 'title',
          base: ['Resource'],
          type: 'string',
          expression: 'Resource.qualification[0].code.coding[0].code',
        },
      ];
    } else if (field.name === 'email') {
      field['searchParams'] = [
        {
          resourceType: 'SearchParameter',
          code: 'email',
          name: 'email',
          base: ['Resource'],
          type: 'string',
          expression: "Resource.telecom.where(system='email').value",
        },
      ];
    }
  });

  const filterResources = (resources: any[]) => {
    return resources.filter((resource) => {
      // Filter by searchQuery
      const searchableFields = fields.map((field) => field.name);
      const matchesSearchQuery =
        !searchQuery ||
        searchableFields.some((fieldName) => {
          let value;
          if (fieldName === 'patient' || fieldName === 'name') {
            value = resource?.name?.[0]?.given?.[0]
              ? resource?.name?.[0]?.given?.[0] + ' ' + resource?.name?.[0]?.family
              : resource?.patient?.name;
          } else if (fieldName === 'phone') {
            value = resource?.telecom?.find((telecom: { system: string }) => telecom.system === 'phone')?.value;
          } else if (fieldName === 'practitioner') {
            value = resource?.practitioner?.name;
          } else {
            value = resource[fieldName];
          }

          return value?.toString().toLowerCase().includes(searchQuery.toLowerCase());
        });

      // Filter by activeFilterTab

      if (location.pathname.split('/')[1] !== 'Patient') {
        const matchesFilterTab = !activeFilterTab || resource.status === activeFilterTab;
        return matchesSearchQuery && matchesFilterTab;
      }

      // Return true if the resource matches both filters
      return matchesSearchQuery;
    });
  };

  return (
    <>
      <Header />
      <div data-testid="search-control" className="tw-py-5 tw-px-6">
        {/* <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
          <div>
            <div className="tw-flex tw-items-center tw-justify-between">
              <h1 className="tw-text-sm tw-mb-1 tw-mr-2">
                {location.pathname.split('/')[1]}s
              </h1>
            </div>
          </div>
        </div> */}

        <div className="tw-flex tw-w-full tw-justify tw-bg-[#F9FAFB] tw-pt-4 tw-px-4 tw-rounded-lg">
          {/* Search Input */}
          <div className="tw-flex-0">
            <SearchAndFilter
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              state={state}
              setState={setState}
            />
          </div>

          <div className="tw-flex-1 tw-justify-end tw-w-full"> </div>

          {/* Tags */}
          {location.pathname.split('/')[1] !== 'Patient' && (
            <div className="tw-flex-shrink-0 tw-mr-4">
              <FilterTags
                activeFilterTab={activeFilterTab}
                setActiveFilterTab={setActiveFilterTab}
                resourceType={search.resourceType}
              />
            </div>
          )}

          {/* Add New Button */}
          {props.onNew && resourceType !== 'Questionnaire' && (
            <button
              className="tw-inline-flex tw-items-center tw-me-2 tw-border tw-border-[#3CA5A9] tw-rounded-[4px] tw-py-1 tw-px-2 tw-bg-[#9552E8] tw-text-[#fff] tw-font-semibold tw-h-10 tw-w"
              onClick={props.onNew}
              style={{ backgroundColor: brandDetails?.buttonColor }}
            >
              <IconPlus className="tw-font-semibold tw-me-2" size={20} />
              Add New{' '}
              {location.pathname.split('/')[1] === 'WaitingList' ? 'Waiting List' : location.pathname.split('/')[1]}
            </button>
          )}
        </div>

        <div className="tw-flex tw-items-baseline tw-justify-between">
          <div>
            <div className="tw-flex tw-items-center">
              <h1 className="tw-text-[18px] tw-font-semibold tw-mr-2 tw-text-[#101828]">
                {location.pathname.split('/')[1] === 'Questionnaire'
                  ? 'Assessments'
                  : `${location.pathname.split('/')[1]}s`}
              </h1>
            </div>
          </div>
          <div className="tw-flex tw-gap-2 tw-justify-end tw-pt-4">
            {location.pathname.split('/')[1] === 'Appointment' && (
              <button className="tw-inline-flex tw-items-center tw-me-2 tw-border tw-border-[#D0D5DD] tw-rounded-lg tw-py-2 tw-px-2 tw-bg-[#F9FAFB] tw-text-[#202939] tw-font-semibold">
                <IconCalendar className="tw-font-semibold tw-me-2" size={20} />
                Calendar View
              </button>
            )}
          </div>
        </div>

        {/* Table */}
        <div className="tw-bg-white tw-mt-8 tw-relative tw-overflow-x-auto tw-rounded-xl table-container ">
          <table className=" tw-w-full tw-text-sm tw-text-left listing_table">
            <thead className="tw-text-xs tw-text-gray-700 tw-bg-[#F9FAFB] tw-border-b-[1px] tw-border-[#EAECF0]">
              <tr className="tw-py-3 tw-pl-[1.5rem]">
                {fields.map((field, index) => (
                  <th key={index} className="tw-py-1 tw-px-[0.9rem] tw-text-[#475467] tw-font-medium tw-text-sm">
                    <div className="tw-flex tw-justify-between tw-items-center tw-w-max ">
                      {buildFieldNameString(
                        field.name === 'start'
                          ? 'Start Date'
                          : field.name === 'end'
                            ? 'End Date'
                            : field.name === 'practitioner'
                              ? 'Provider'
                              : field.name === 'appointmentType'
                                ? 'Appointment Type'
                                : field.name === 'date'
                                  ? 'Date & Time'
                                  : field.name === 'description'
                                    ? 'Description'
                                    : field.name
                      )}

                      <div>
                        <Menu shadow="md" width={240} position="bottom-end">
                          <Menu.Target>
                            <UnstyledButton className="tw-p-2">
                              <div>
                                <IconAdjustmentsHorizontal size={14} stroke={1.5} />
                              </div>
                            </UnstyledButton>
                          </Menu.Target>
                          <SearchPopupMenu
                            search={props.search}
                            searchParams={field.searchParams}
                            onPrompt={(searchParam, filter) => {
                              setState({
                                ...stateRef.current,
                                filterDialogVisible: true,
                                filterDialogSearchParam: searchParam,
                                filterDialogFilter: filter,
                              });
                            }}
                            onChange={(result) => {
                              emitSearchChange(result);
                            }}
                          />
                        </Menu>
                      </div>
                    </div>
                  </th>
                ))}
                <th className="tw-py-3 tw-px-6 tw-text-[#475467] tw-font-medium tw-text-sm">Actions</th>
              </tr>
            </thead>

            <tbody>
              {filterResources(resources || [])?.map((resource: any) => (
                <tr key={resource.id} className="tw-border-b hover:tw-bg-gray-50 tw-cursor-pointer">
                  {fields.map((field, index) => (
                    <td key={index} className="tw-px-[0.9rem] tw-py-5 tw-font-normal tw-text-[#475467]">
                      {(() => {
                        const fieldMap: Record<string, any> = {
                          practitioner: resource.participant?.find((participant: any) =>
                            participant?.actor?.reference?.includes('Parctitioner')
                          )?.actor.display,
                          start: resource?.start
                            ? format(new Date(resource.start), 'MMM dd yyyy hh:mm a').replace(
                                'yyyy',
                                new Date(resource.start).getFullYear().toString()
                              )
                            : '',
                          end: resource?.end
                            ? format(new Date(resource.end), 'MMM dd yyyy hh:mm a').replace(
                                'yyyy',
                                new Date(resource.end).getFullYear().toString()
                              )
                            : '',
                          status: (
                            <StatusBadge
                              statusLabel={
                                resourceType === 'Questionnaire' && (resource as ResourceWithStatus).status === 'active'
                                  ? 'New'
                                  : resourceType === 'Questionnaire' &&
                                      (resource as ResourceWithStatus).status === 'retired'
                                    ? 'Completed'
                                    : (resource as ResourceWithStatus).status
                              }
                              statusColor={STATUS_COLORS[(resource as ResourceWithStatus).status]}
                              badgeColor={'#a6aaae'}
                              textColor={STATUS_COLORS[(resource as ResourceWithStatus).status]}
                            />
                          ),
                          appointmentType: resource?.appointmentType?.coding?.[0]?.display,
                          date: resource?.date
                            ? format(new Date(resource.date), 'MMM dd yyyy hh:mm a').replace(
                                'yyyy',
                                new Date(resource.date).getFullYear().toString()
                              )
                            : '',
                          name: resource?.name,
                          description: resource?.description,
                        };
                        return fieldMap[field.name] || renderValue(resource, field);
                      })()}
                    </td>
                  ))}
                  <td className="tw-px-6 tw-py-4">
                    <div className="tw-flex tw-gap-4">
                      {resourceType === 'Questionnaire' ? (
                        (resource as ResourceWithStatus).status === 'active' ? (
                          <IconDirectionSign
                            size={22}
                            stroke={'1.67px'}
                            className="tw-text-[#475467] hover:tw-text-red-500 tw-cursor-pointer"
                            onClick={() => {
                              setIsResourceId(resource.id);
                              if (location.pathname === '/Questionnaire') {
                                setIsQuestionnaire(true);
                              }
                            }}
                          />
                        ) : (
                          <IconEye
                            size={22}
                            stroke={'1.67px'}
                            className="tw-text-[#475467] hover:tw-text-red-500 tw-cursor-pointer"
                            onClick={() => {
                              setIsResourceId(resource.id);
                              if (location.pathname === '/Questionnaire') {
                                setIsQuestionnaire(true);
                              }
                            }}
                          />
                        )
                      ) : (
                        <>
                          <IconTrash
                            size={22}
                            stroke={'1.67px'}
                            className="tw-text-[#475467] hover:tw-text-red-500 tw-cursor-pointer"
                          />
                          <IconPencil
                            size={22}
                            stroke={'1.67px'}
                            className="tw-text-[#475467] hover:tw-text-blue-500 tw-cursor-pointer"
                            onClick={() => {
                              setIsResourceId(resource.id);
                              if (location.pathname === '/Appointment') {
                                setIsAppointment(true);
                              }
                            }}
                          />
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {filterResources(resources || []).length === 0 && (
            <Container>
              <Center style={{ height: 150 }}>
                <Text size="xl" c="dimmed">
                  No results Found
                </Text>
              </Center>
            </Container>
          )}
          {/* Pagination */}
          {lastResult && (
            <div className="tw-flex tw-justify-between tw-items-center tw-mt-4 tw-m-3">
              <button
                className={`tw-px-4 tw-flex tw-items-center tw-py-2 tw-text-sm tw-font-semibold tw-text-[#344054] ${getPage(search) <= 1 ? 'tw-opacity-50' : ' tw-bg-white hover:tw-bg-gray-50 tw-cursor-pointer'} tw-border tw-border-[#D0D5DD] tw-rounded-lg `}
                onClick={() => {
                  const newPage = Math.max(1, getPage(search) - 1);
                  emitSearchChange(setPage(search, newPage));
                }}
                disabled={getPage(search) <= 1}
                style={{ color: brandDetails?.defaultTextColor }}
              >
                &larr; Previous
              </button>
              <div className="tw-flex tw-items-center">
                {[...Array(Math.min(10, getTotalPages(search, lastResult)))].map((_, index) => {
                  return (
                    <button
                      key={index}
                      className={`tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-rounded-lg ${
                        index + 1 === getPage(search)
                          ? 'tw-bg-[#F9FAFB] tw-text-gray-700'
                          : 'tw-text-gray-700 tw-bg-white'
                      }`}
                      onClick={() => {
                        const newPage = Math.max(1, getPage(search) - 1);
                        emitSearchChange(setPage(search, newPage));
                      }}
                    >
                      {index + 1}
                    </button>
                  );
                })}
              </div>
              <button
                className={`tw-px-4 tw-flex tw-items-center tw-py-2 tw-text-sm tw-font-semibold tw-text-[#344054] ${getPage(search) >= getTotalPages(search, lastResult) ? 'tw-opacity-50' : ' tw-bg-white hover:tw-bg-gray-50 tw-cursor-pointer'} tw-border tw-border-[#D0D5DD] tw-rounded-lg`}
                onClick={() => {
                  const newPage = Math.min(getTotalPages(search, lastResult), getPage(search) + 1);
                  emitSearchChange(setPage(search, newPage));
                }}
                disabled={getPage(search) >= getTotalPages(search, lastResult)}
                style={{ color: brandDetails?.defaultTextColor }}
              >
                Next &rarr;
              </button>
            </div>
          )}
        </div>

        {outcome && (
          <div data-testid="search-error">
            <pre style={{ textAlign: 'left' }}>{JSON.stringify(outcome, undefined, 2)}</pre>
          </div>
        )}
        <SearchFieldEditor
          search={props.search}
          visible={stateRef.current.fieldEditorVisible}
          onOk={(result) => {
            emitSearchChange(result);
            setState({
              ...stateRef.current,
              fieldEditorVisible: false,
            });
          }}
          onCancel={() => {
            setState({
              ...stateRef.current,
              fieldEditorVisible: false,
            });
          }}
        />
        <SearchFilterEditor
          search={props.search}
          visible={stateRef.current.filterEditorVisible}
          onOk={(result) => {
            emitSearchChange(result);
            setState({
              ...stateRef.current,
              filterEditorVisible: false,
            });
          }}
          onCancel={() => {
            setState({
              ...stateRef.current,
              filterEditorVisible: false,
            });
          }}
        />
        <SearchFilterValueDialog
          key={state.filterDialogSearchParam?.code}
          visible={stateRef.current.filterDialogVisible}
          title={state.filterDialogSearchParam?.code ? buildFieldNameString(state.filterDialogSearchParam.code) : ''}
          resourceType={resourceType}
          searchParam={state.filterDialogSearchParam}
          filter={state.filterDialogFilter}
          defaultValue=""
          onOk={(filter) => {
            emitSearchChange(addFilter(props.search, filter.code, filter.operator, filter.value));
            setState({
              ...stateRef.current,
              filterDialogVisible: false,
            });
          }}
          onCancel={() => {
            setState({
              ...stateRef.current,
              filterDialogVisible: false,
            });
          }}
        />
        {isAppointment && (
          <Appointments opened={isAppointment} close={() => setIsAppointment(false)} appointmentId={isResourceId} />
        )}
        {isQuestionnaire && (
          <AssessmentQuestionsDrawer
            opened={isQuestionnaire}
            close={() => setIsQuestionnaire(false)}
            questionnaireId={isResourceId}
          />
        )}
      </div>
    </>
  );
}

export const MemoizedSearchControl = memo(SearchControl);

function getPage(search: SearchRequest): number {
  return Math.floor((search.offset ?? 0) / (search.count ?? DEFAULT_SEARCH_COUNT)) + 1;
}

function getTotalPages(search: SearchRequest, lastResult: Bundle): number {
  const pageSize = search.count ?? DEFAULT_SEARCH_COUNT;
  const total = getTotal(search, lastResult);
  return Math.ceil(total / pageSize);
}

function getTotal(search: SearchRequest, lastResult: Bundle): number {
  let total = lastResult.total;
  if (total === undefined) {
    // If the total is not specified, then we have to estimate it
    total =
      (search.offset ?? 0) +
      (lastResult.entry?.length ?? 0) +
      (lastResult.link?.some((l) => l.relation === 'next') ? 1 : 0);
  }
  return total;
}
