/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { useMedplum } from '@medplum/react';
import { Drawer, Divider } from '@mantine/core';
import { Questionnaire, QuestionnaireResponse } from '@medplum/fhirtypes';
import QuestionnaireForm from './QuestionnaireForm';
import AssessmentPreview from './AssessmentPreview';
import { useBrandSettings } from '../../BrandContext';
import Assessment from '../../components/Assessment';

interface AssessmentQuestionsProps {
  opened: boolean;
  close: () => void;
  questionnaireId: string;
}

const AssessmentQuestionsDrawer = (props: AssessmentQuestionsProps) => {
  const medplum = useMedplum();
  const brandDetails = useBrandSettings();
  const [questionnaire, setQuestionnaire] = useState<Questionnaire>();
  const [setStaus, setStatus] = useState<string>('');
  const [activeStep, setActiveStep] = useState(0);
  const [questionnaireResponse, setQuestionnaireResponse] = useState<QuestionnaireResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [assessmentCompletionDate, setAssessmentCompletionDate] = useState('');
  const [assessmentInsightsSummaryData, setAssessmentInsightsSummaryData] = useState<string[] | undefined>([]);
  const [hasData, setHasData] = useState<boolean>(true);
  const stepLabels = ['Assessment Response Overview', 'AI Insights'];

  useEffect(() => {
    if (props.opened) {
      getQuestionnaire();
    }
  }, [props.opened]);

  // Fetch questionnaire by ID
  const getQuestionnaire = () => {
    medplum
      .readResource('Questionnaire', props.questionnaireId)
      .then((questionnaire) => {
        setStatus(questionnaire?.status);
        setQuestionnaire(questionnaire);
        if (questionnaire && questionnaire.status === 'retired') {
          getQuestionnaireResponse(questionnaire?.id);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getQuestionnaireResponse = (questionnaireId?: string) => {
    setLoading(true);
    medplum
      .search('QuestionnaireResponse', {
        questionnaire: `Questionnaire/${questionnaireId}`,
      })
      .then((response) => {
        const entry = response?.entry?.find((entry) => entry?.resource?.resourceType === 'QuestionnaireResponse');
        setQuestionnaireResponse(entry?.resource as QuestionnaireResponse);
        setAssessmentCompletionDate(entry?.resource?.authored || '');
        // Get AI insights
        const insights = entry?.resource?.extension?.find((data: any) => data.url === 'http://ai-insights');
        if (insights) {
          const lines = insights?.valueString?.split('\n');
          setAssessmentInsightsSummaryData(lines);
          setHasData(true);
        } else {
          setHasData(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  return (
    <Drawer.Root offset={8} position="right" size="55%" radius="md" opened={props.opened} onClose={props.close}>
      <Drawer.Overlay />
      <Drawer.Content>
        <Drawer.Header>
          <Drawer.Title className="tw-font-medium tw-text-[16px]">
            {questionnaire &&
              (setStaus === 'active' ? (
                'Assessment Questions'
              ) : (
                <div className="tw-flex tw-justify-between tw-gap-5 tw-mb-5">
                  {stepLabels.map((label, index) => (
                    <button
                      key={label}
                      className="tw-text-[#344054] tw-p-2 tw-border-none tw-bg-[#fff] tw-cursor-pointer tw-font-semibold tw-text-[14px] tw-font-semibold tw-text-left tw-border-b-2 tw-border-[#a4a7ab]"
                      style={{
                        borderBottom:
                          index === activeStep ? `2px solid ${brandDetails?.buttonColor}` : '2px solid #a4a7ab',
                      }}
                      onClick={() => {
                        setActiveStep(index);
                      }}
                    >
                      {label}
                    </button>
                  ))}
                </div>
              ))}
          </Drawer.Title>
          <Drawer.CloseButton />
        </Drawer.Header>
        <Divider />
        <Drawer.Body>
          {questionnaire &&
            (setStaus === 'active' ? (
              <QuestionnaireForm questionnaire={questionnaire} close={props.close} />
            ) : activeStep === 0 || setStaus === 'active' ? (
              <AssessmentPreview
                questionnaire={questionnaire}
                questionnaireResponse={questionnaireResponse}
                loading={loading}
              />
            ) : activeStep === 1 ? (
              <div className="tw-bg-[#fff] tw-px-3 tw-pt-5 tw-rounded-b-lg tw-text-[#101828] tw-text-[18px] tw-font-semibold">
                <Assessment
                  assessmentCompletionDate={assessmentCompletionDate}
                  assessmentInsightsSummaryData={assessmentInsightsSummaryData}
                  hasData={hasData}
                />
              </div>
            ) : null)}
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};

export default AssessmentQuestionsDrawer;
