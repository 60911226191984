export class FeatureDescriptions {
  static getDescription(featureTitle: string): string | undefined {
    const descriptions: { [key: string]: string } = {
      Medications: "Retrieve the list of the patient's current and past medications.",
      'Medical Records': 'Retrieve all the medical records and export them as a PDF.',
      'Health Summary': "Retrieve the patient's progress report.",
      'Clinical Trial Eligibility': 'Check if the patient is eligible for any ongoing clinical trials.',
      'Lab Results': 'Can you provide my latest lab results and any key insights?',
      'Treatment Plans': 'Can you give me a summary of my treatment plan, including key medications?',
      'Lifestyle & Diet': 'What diet and exercise recommendations are suitable for my condition?',
      'Upcoming Appointments': 'When is my next appointment, and can I get details to reschedule if needed?',
    };

    return descriptions[featureTitle];
  }

  static getFeatures() {
    return [
      {
        icon: <img src="../img/medication.svg" alt="Medications" style={{ width: 20, height: 20 }} />,
        title: 'Medications',
      },
      {
        icon: <img src="../img/medical_record.svg" alt="Medical Records" style={{ width: 20, height: 20 }} />,
        title: 'Medical Records',
      },
      {
        icon: <img src="../img/health_summary.svg" alt="Health Summary" style={{ width: 20, height: 20 }} />,
        title: 'Health Summary',
      },
      {
        icon: (
          <img src="../img/clinical_trial.svg" alt="Clinical Trial Eligibility" style={{ width: 20, height: 20 }} />
        ),
        title: 'Clinical Trial Eligibility',
      },
      {
        icon: <img src="../img/lab_results.svg" alt="Lab Results" style={{ width: 20, height: 20 }} />,
        title: 'Lab Results',
      },
      {
        icon: <img src="../img/treatment_plans.svg" alt="Treatment Plans" style={{ width: 20, height: 20 }} />,
        title: 'Treatment Plans',
      },
      {
        icon: <img src="../img/life_styles.svg" alt="Lifestyle & Diet" style={{ width: 20, height: 20 }} />,
        title: 'Lifestyle & Diet',
      },
      {
        icon: (
          <img src="../img/upcoming_appointments.svg" alt="Upcoming Appointments" style={{ width: 20, height: 20 }} />
        ),
        title: 'Upcoming Appointments',
      },
    ];
  }
}
