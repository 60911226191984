/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable array-callback-return */
import { AppShell, Loading, Logo, useMedplum, useMedplumProfile } from '@medplum/react';
import {
  IconCalendarTime,
  IconHome,
  IconInvoice,
  IconMail,
  IconMicroscope,
  IconNotes,
  IconVideo,
  IconRobotFace,
} from '@tabler/icons-react';
import { Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import { ResourceCreatePage } from './pages/resource/ResourceCreatePage';
import { OnboardingPage } from './pages/OnboardingPage';
import { SearchPage } from './pages/SearchPage';
import { SignInPage } from './pages/SignInPage';
import { EditPatientProfile } from './pages/patient/EditPatientProfile';
import { ChangePassword } from './pages/patient/ChangePassword';
import { EncounterTab } from './pages/patient/EncounterTab';
import { PatientPage } from './pages/patient/PatientPage';
import { PatientSearchPage } from './pages/patient/PatientSearchPage';
import { TimelineTab } from './pages/patient/TimelineTab';
import { ResourceDetailPage } from './pages/resource/ResourceDetailPage';
import { ResourceEditPage } from './pages/resource/ResourceEditPage';
import { ResourceHistoryPage } from './pages/resource/ResourceHistoryPage';
import { ResourcePage } from './pages/resource/ResourcePage';
import { CommunicationTab } from './pages/patient/CommunicationTab';
import { TaskTab } from './pages/patient/TaskTab';
import Index from './dashboard';
import Messages from './dashboard/Messages';
import TeleHealth from './dashboard/TeleHealth';
import './index.css';
import './App.css';
import '@mantine/dates/styles.css';
import { ListingPage } from './dashboard/HomePage';
import Patient from './dashboard/PatientResourcePage';
import Login from './pages/Login';
import { getPatientPortalMenus } from './utils/CustomAPI';
import { Space } from '@mantine/core';
import { menus } from './utils/constant';
import FhirBot from './dashboard/AIAssistant/FhirBot';

interface SidebarMenuProps {
  title: string;
  links: MenuLinks;
}

interface MenuLink {
  name: string;
  target: string;
}

type MenuLinks = MenuLink[];

export function App(): JSX.Element | null {
  const medplum = useMedplum();
  const profile = useMedplumProfile();
  const [searchParams] = useSearchParams();
  const [sidebarMenu, setSidebarMenu] = useState<SidebarMenuProps[]>([]);

  useEffect(() => {
    getPatientPortalMenus(medplum)
      .then((res) => {
        if (res) {
          setSidebarMenu([{ title: '', links: res }]);
        } else {
          setSidebarMenu(menus);
        }
      })
      .catch((err) => {
        console.log(err);
        setSidebarMenu(menus);
      });
  }, []);

  if (medplum.isLoading()) {
    return null;
  }

  return (
    <AppShell
      logo={<Logo size={150} />}
      pathname={location.pathname}
      searchParams={searchParams}
      menus={userConfigToMenu(sidebarMenu)}
      resourceTypeSearchDisabled={true}
    >
      <Suspense fallback={<Loading />}>
        <Routes>
          {profile ? (
            <>
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route path="/dashboard" element={<Index />} />
              <Route path="edit-Profile" element={<EditPatientProfile />} />
              <Route path="change-password" element={<ChangePassword />} />
              <Route path="/Patient/:patientId" element={<PatientPage />}>
                <Route path="edit" element={<EditPatientProfile />} />
                <Route path="encounter" element={<EncounterTab />} />
                <Route path="communication" element={<CommunicationTab />} />
                <Route path="communication/:id" element={<CommunicationTab />} />
                <Route path="task/:id/*" element={<TaskTab />} />
                <Route path="timeline" element={<TimelineTab />} />
                <Route path=":resourceType" element={<PatientSearchPage />} />
                <Route path=":resourceType/new" element={<ResourceCreatePage />} />
                <Route path=":resourceType/:id" element={<ResourcePage />}>
                  <Route path="" element={<ResourceDetailPage />} />
                  <Route path="edit" element={<ResourceEditPage />} />
                  <Route path="history" element={<ResourceHistoryPage />} />
                </Route>
                <Route path="" element={<TimelineTab />} />
              </Route>
              <Route path="/onboarding" element={<OnboardingPage />} />
              {/* <Route path="/signin" element={<SignInPage />} /> */}
              <Route path="/:resourceType" element={<SearchPage />} />
              <Route path="/:resourceType/new" element={<ResourceCreatePage />} />
              <Route path="/:resourceType/:id" element={<ResourcePage />}>
                <Route path="" element={<ResourceDetailPage />} />
                <Route path="edit" element={<ResourceEditPage />} />
                <Route path="history" element={<ResourceHistoryPage />} />
              </Route>
              <Route path="/dashboard" element={<Index />} />
              <Route path="/Appointment" element={<ListingPage />} />
              <Route path="/Questionnaire" element={<ListingPage />} />
              <Route path="/mywellness-ai" element={<FhirBot />} />
              <Route path="/ai-assistant" element={<FhirBot />} />
              <Route path="/WaitingList" element={<ListingPage />} />
              <Route path="/Patient" element={<ListingPage />} />
              <Route path="/Patient/:id/details" element={<Patient />} />
              <Route path="/message" element={<Messages />} />
              <Route path="/telehealth" element={<TeleHealth />} />
            </>
          ) : (
            <>
              <Route path="/signin" element={<SignInPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/signin" replace />} />
            </>
          )}
        </Routes>
      </Suspense>
    </AppShell>
  );
}

function userConfigToMenu(config: SidebarMenuProps[]) {
  return (
    config?.map((menu) => {
      return {
        title: '',
        links:
          menu?.links?.map((link: { name: string; target: string }) => ({
            label: getDisplayName(link.name).displayName,
            href: link.target,
            icon: getIcon(link.name),
          })) || [],
      };
    }) || []
  );
}

const resourceTypeToIcon: Record<string, React.ElementType> = {
  Dashboard: IconHome,
  Appointments: IconCalendarTime,
  Assessments: IconNotes,
  LabTests: IconMicroscope,
  Messages: IconMail,
  Telehealth: IconVideo,
  Invoices: IconInvoice,
  Notes: IconNotes,
  'Mywellness AI': IconRobotFace,
  'AI Assistant': IconRobotFace,
};

function getIcon(name: string): JSX.Element | undefined {
  try {
    if (name in resourceTypeToIcon) {
      const Icon = resourceTypeToIcon[name];
      return <Icon />;
    }
  } catch (e) {
    // Ignore
  }
  return <Space w={30} />;
}

const resourceTypeToDisplayName: Record<string, string> = {
  Dashboard: 'Dashboard',
  Appointments: 'Appointments',
  Assessments: 'Assessments',
  Messages: 'Messages',
  Telehealth: 'Telehealth',
  LabTests: 'Lab Tests',
  Invoices: 'Invoices',
  Notes: 'Notes',
  MywellnessAI: 'Mywellness AI',
  AIAssistant: 'AI Assistant',
};

function getDisplayName(name: string) {
  let displayName = name;
  if (name in resourceTypeToDisplayName) {
    displayName = resourceTypeToDisplayName[name];
  }
  return { displayName };
}
