import React from 'react';
import { Container, Center } from '@mantine/core';

const ComingSoonPage: React.FC = () => {
  return (
    <Container size="sm" style={{ textAlign: 'center' }}>
      <Center>
        <img src="../img/coming-soon.svg" alt="coming soon Logo" style={{ width: '70%', marginTop: 50 }} />
      </Center>
    </Container>
  );
};

export default ComingSoonPage;
