import React from 'react';
import ComingSoonPage from '../../../app/src/pages/ComingSoonPage';

const Messages: React.FC = () => {
  return (
    <ComingSoonPage />
  );
};

export default Messages;
