import React, { useEffect, useState } from 'react';
import { Drawer, Divider } from '@mantine/core';
import { useBrandSettings } from '../../BrandContext';
import { QuestionnaireResponse } from '@medplum/fhirtypes';
import AssessmentPreview from './AssessmentPreview';
import Assessment from '../../components/Assessment';

interface AssessmentDetailsProps {
  opened: boolean;
  close: () => void;
  selectedData?: QuestionnaireResponse;
  assessmentName?: string;
}
const AssessmentDetailsModel = (props: AssessmentDetailsProps) => {
  const brandDetails = useBrandSettings();
  const stepLabels = ['Assessment Response Overview', 'AI Insights'];
  const [activeStep, setActiveStep] = useState(0);
  const [questionnaireResponse, setQuestionnaireResponse] = useState<QuestionnaireResponse>();
  const [assessmentCompletionDate, setAssessmentCompletionDate] = useState('');
  const [assessmentInsightsSummaryData, setAssessmentInsightsSummaryData] = useState<string[] | undefined>([]);
  const [hasData, setHasData] = useState<boolean>(true);

  useEffect(() => {
    if (props.opened) {
      setQuestionnaireResponse(props.selectedData);
      if (props.selectedData) {
        geAItInsights(
          props.selectedData.extension?.find((entry: any) => entry.url === 'http://ai-insights'),
          props.selectedData.authored || ''
        );
      }
    }
  }, [props.opened]);

  const geAItInsights = (data: any, date: string) => {
    setAssessmentCompletionDate(date || '');
    if (data) {
      const lines = data?.valueString?.split('\n');
      setAssessmentInsightsSummaryData(lines);
      setHasData(true);
    } else {
      setHasData(false);
    }
  };

  return (
    <Drawer.Root offset={8} position="right" size="55%" radius="md" opened={props.opened} onClose={props.close}>
      <Drawer.Content>
        <Drawer.Header>
          <Drawer.Title className="tw-font-medium tw-text-[16px]">
            <div className="tw-flex tw-justify-between tw-gap-5 tw-mb-5">
              {stepLabels.map((label, index) => (
                <button
                  key={label}
                  className="tw-text-[#344054] tw-p-2 tw-border-none tw-bg-[#fff] tw-cursor-pointer tw-font-semibold tw-text-[14px] tw-font-semibold tw-text-left tw-border-b-2 tw-border-[#a4a7ab]"
                  style={{
                    borderBottom: index === activeStep ? `2px solid ${brandDetails?.buttonColor}` : '2px solid #a4a7ab',
                  }}
                  onClick={() => {
                    setActiveStep(index);
                  }}
                >
                  {label}
                </button>
              ))}
            </div>
          </Drawer.Title>
          <Drawer.CloseButton />
        </Drawer.Header>
        <Divider />
        <Drawer.Body>
          {activeStep === 0 ? (
            <AssessmentPreview questionnaireResponse={questionnaireResponse} assessmentName={props.assessmentName} />
          ) : activeStep === 1 ? (
            <div className="tw-bg-[#fff] tw-px-3 tw-pt-5 tw-rounded-b-lg tw-text-[#101828] tw-text-[18px] tw-font-semibold">
              <Assessment
                assessmentCompletionDate={assessmentCompletionDate}
                assessmentInsightsSummaryData={assessmentInsightsSummaryData}
                hasData={hasData}
              />
            </div>
          ) : null}
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};

export default AssessmentDetailsModel;
