import {
  Button,
  Group,
  Stack,
  TextInput,
  PasswordInput,
  Select,
  Text,
  Anchor,
  Loader,
  Textarea,
  Notification,
} from '@mantine/core';
import { useState } from 'react';
import { useBrandSettings } from '../../../app/src/BrandContext';
import {
  registerNewUser,
  registerNewPatient,
  getPrimaryConditions,
  createPatientCondition,
} from '../../../app/src/utils/CustomAPI';
import debounce from 'lodash/debounce';
import { showNotification } from '@mantine/notifications';

const stepperStyles = (brandColor: string) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'flex-start' as 'flex-start',
    width: '100%',
    marginBottom: '20px',
  },
  steps: { display: 'flex', flexDirection: 'row' as 'row', alignItems: 'flex-start' as 'flex-start', width: '100%' },
  stepContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'flex-start' as 'flex-start',
    margin: '0 5px',
    width: '50%',
  },
  stepLabel: (isActive: boolean) => ({
    marginBottom: '5px',
    color: isActive ? brandColor : '#808080',
    fontWeight: isActive ? 'bold' : 'normal',
    fontSize: '12px',
    textAlign: 'left',
    width: '100%',
  }),
  stepLine: (isActive: boolean) => ({
    backgroundColor: isActive ? brandColor : '#808080',
    height: '2px',
    width: '100%',
    margin: '5px 0',
  }),
  statusText: { marginTop: '10px' },
  dash: { marginBottom: '28px', color: '#41dfdf' },
});

const stepLabels = [<>Basic Details</>, <>Password</>, <>Conditions</>];

export function RegistrationStepper({ onLoginClick }: { onLoginClick: () => void }) {
  const brandDetails = useBrandSettings();
  const brandColor = brandDetails?.textColor || '#9552E8';
  const styles = stepperStyles(brandColor);
  const [active, setActive] = useState(0);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    condition: '',
    stage: '',
    severity: '',
    comments: '',
  });
  const [patientId, setPatientId] = useState<string | null>(null);
  const [primaryConditions, setPrimaryConditions] = useState<{ value: string; label: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [searching, setSearching] = useState(false);
  const [searchText, setSearchText] = useState('');

  // Debounced function to call API after user input
  const fetchConditions = debounce(async (searchText: string) => {
    if (searchText.length >= 3) {
      setSearching(true);
      const response = await getPrimaryConditions(searchText);
      if (response) {
        setPrimaryConditions(
          response.primaryConditionsDropdown.map((item: { code: string; display: string }) => ({
            value: item.code,
            label: item.display,
          }))
        );
      }
      setSearching(false);
    } else {
      setPrimaryConditions([]);
    }
  }, 300);

  const handleConfirmPasswordChange = (value: string) => {
    setFormData((prev) => {
      const updatedFormData = { ...prev, confirmPassword: value };
      if (
        !updatedFormData.password ||
        !updatedFormData.confirmPassword ||
        updatedFormData.password !== updatedFormData.confirmPassword
      ) {
        setErrorMessage('Confirm password does not match the password.');
      } else {
        setErrorMessage('');
      }
      return updatedFormData;
    });
  };

  const nextStep = () => {
    if (active === 0) {
      if (!formData.firstName || !formData.lastName || !formData.email) {
        setErrorMessage('Please fill in all required fields.');
        return;
      }
    }

    if (active === 1) {
      if (!formData.password || !formData.confirmPassword || formData.password !== formData.confirmPassword) {
        setErrorMessage('Passwords do not match or are not filled in correctly.');
        return;
      }

      const userPayload = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        projectName: brandDetails?.brandName ?? '',
        password: formData.password,
        remember: false,
        recaptchaToken: '',
      };

      registerNewUser(userPayload)
        .then((userResponse) => {
          const typedResponse = userResponse as {
            response?: { data?: { resourceType?: string; issue?: { details?: { text: string } }[] } };
            data?: { login?: string };
          };
          // Check if response indicates an OperationOutcome
          if (typedResponse.response?.data?.resourceType === 'OperationOutcome') {
            const issue = typedResponse.response.data.issue?.[0];
            showNotification({
              color: 'red',
              message: issue?.details?.text || 'An unknown error occurred.',
            });
            return;
          }

          // Check if login exists in the response
          if (typedResponse.data?.login) {
            const patientPayload = {
              login: typedResponse.data.login,
              projectName: brandDetails?.brandName ?? '',
            };

            registerNewPatient(patientPayload)
              .then((patientResponse) => {
                const typedPatientResponse = patientResponse as {
                  data?: { resourceType?: string; issue?: { details?: { text: string } }[]; patientId?: string };
                };
                // Check if response indicates an OperationOutcome
                if (typedPatientResponse.data?.resourceType === 'OperationOutcome') {
                  const issue = typedPatientResponse.data.issue?.[0];
                  showNotification({
                    color: 'red',
                    message: issue?.details?.text || 'An unknown error occurred.',
                  });
                  return;
                }

                setPatientId(typedPatientResponse.data?.patientId || '');
                setActive((current) => (current < 2 ? current + 1 : current));
              })
              .catch((error) => {
                console.error('Error registering patient:', error);
                showNotification({ color: 'red', message: 'Error registering patient' });
              });
          } else {
            showNotification({ color: 'red', message: 'An unknown error occurred.' });
          }
        })
        .catch((error) => {
          console.error('Error registering user:', error);
          showNotification({ color: 'red', message: 'Error registering user' });
        });
    } else {
      setActive((current) => (current < 2 ? current + 1 : current));
    }

    setErrorMessage(null);
  };

  const prevStep = () => {
    setErrorMessage(null);
    setActive((current) => (current > 0 ? current - 1 : current));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    if (patientId) {
      const conditionPayload: any = {
        patientId: patientId,
        primaryCondition: {
          code: formData.condition || '',
          display: primaryConditions.find((item) => item.value === formData.condition)?.label || '',
        },
        stage: {
          code: formData.stage || '',
          display:
            [
              { value: '13104003', label: 'Clinical stage I' },
              { value: '60333009', label: 'Clinical stage II' },
              { value: '50283003', label: 'Clinical stage III' },
              { value: '2640006', label: 'Clinical stage IV' },
            ].find((item) => item.value === formData.stage)?.label || '',
        },
        conditionSeverity: {
          code: formData.severity || '',
          display:
            [
              { value: '24484000', label: 'Severe' },
              { value: '6736007', label: 'Moderate' },
              { value: '255604002', label: 'Mild' },
            ].find((item) => item.value === formData.severity)?.label || '',
        },
        otherComments: formData.comments || '',
        projectName: brandDetails?.brandName ?? '',
        isPrimaryCondition: true,
      };

      const conditionResponse = await createPatientCondition(conditionPayload);
      setLoading(false);
      if (conditionResponse) {
        showNotification({ message: 'Thank you for registering! Log in to get started.', color: 'green' });
        onLoginClick();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (active < 2) {
        nextStep();
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
      <h1 style={{ fontSize: '25px', fontWeight: '600', marginBottom: '10px' }}>Register</h1>
      <Text style={{ textAlign: 'left', color: '#475467', marginTop: '5px', marginBottom: '20px' }}>
        Create an account to access personalized healthcare services and manage your accounts.
      </Text>
      <div style={styles.wrapper}>
        <div style={styles.steps}>
          {stepLabels.map((label, index) => (
            <div key={index} style={styles.stepContainer}>
              <div style={styles.stepLabel(active === index) as React.CSSProperties}>{label}</div>
              <div style={styles.stepLine(active === index)} />
            </div>
          ))}
        </div>
      </div>

      <Stack>
        {active === 0 && (
          <>
            <TextInput
              label="First Name"
              placeholder="Enter your first name"
              required
              value={formData.firstName}
              onChange={(e) => setFormData({ ...formData, firstName: e.currentTarget.value })}
            />
            <TextInput
              label="Last Name"
              placeholder="Enter your last name"
              required
              value={formData.lastName}
              onChange={(e) => setFormData({ ...formData, lastName: e.currentTarget.value })}
            />
            <TextInput
              label="Email"
              placeholder="Enter your email"
              type="email"
              required
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.currentTarget.value })}
            />
          </>
        )}
        {active === 1 && (
          <>
            <PasswordInput
              label="Password"
              placeholder="Enter a password"
              required
              value={formData.password}
              onChange={(e) => setFormData({ ...formData, password: e.currentTarget.value })}
            />
            <PasswordInput
              label="Confirm Password"
              placeholder="Confirm your password"
              required
              value={formData.confirmPassword}
              onChange={(e) => handleConfirmPasswordChange(e.currentTarget.value)}
            />
          </>
        )}
        {active === 2 && (
          <>
            <Select
              label="Condition"
              placeholder="Start typing a condition"
              searchable
              nothingFoundMessage={searchText.length >= 3 && !searching ? 'No conditions found' : null}
              value={formData.condition}
              onSearchChange={(value) => {
                setSearchText(value);
                if (value === '') {
                  setFormData({ ...formData, condition: '' });
                }
                fetchConditions(value);
              }}
              onChange={(value) => {
                setFormData({ ...formData, condition: value || '' });
                setSearchText('');
              }}
              data={primaryConditions}
              styles={{
                option: (theme: any, params: any) => ({
                  '&[data-selected] svg': {
                    display: 'none',
                  },
                }),
              }}
            />
            <Select
              label="Stage"
              placeholder="Select a stage"
              value={formData.stage}
              onChange={(value) => setFormData({ ...formData, stage: value || '' })}
              data={[
                { value: '13104003', label: 'Clinical stage I' },
                { value: '60333009', label: 'Clinical stage II' },
                { value: '50283003', label: 'Clinical stage III' },
                { value: '2640006', label: 'Clinical stage IV' },
              ]}
            />
            <Select
              label="Severity"
              placeholder="Select severity"
              value={formData.severity}
              onChange={(value) => setFormData({ ...formData, severity: value || '' })}
              data={[
                { value: '24484000', label: 'Severe' },
                { value: '6736007', label: 'Moderate' },
                { value: '255604002', label: 'Mild' },
              ]}
            />
            <Textarea
              label="Comments"
              placeholder="Enter any additional comments"
              value={formData.comments}
              onChange={(e) => setFormData({ ...formData, comments: e.currentTarget.value })}
            />
          </>
        )}
      </Stack>

      {errorMessage && (
        <Notification color="red" onClose={() => setErrorMessage(null)}>
          {errorMessage}
        </Notification>
      )}

      <Group align="apart" mt="xl">
        {active > 0 && active < 2 && (
          <Button variant="default" onClick={prevStep}>
            Back
          </Button>
        )}
        {active < 2 ? (
          <Button onClick={nextStep} style={{ backgroundColor: brandColor, color: 'white' }}>
            Next
          </Button>
        ) : (
          <Button type="submit" style={{ backgroundColor: brandColor, color: 'white' }} disabled={loading}>
            {loading ? <Loader size="sm" color="white" /> : 'Submit'}
          </Button>
        )}
      </Group>

      <div className="tw-text-center tw-mt-[20px]">
        Already have an account?{' '}
        <Anchor
          component="button"
          style={{ fontWeight: '400', color: brandColor, fontSize: '15px', marginLeft: '5px' }}
          onClick={onLoginClick}
        >
          Log In
        </Anchor>
      </div>
    </form>
  );
}
