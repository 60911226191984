import { Container, Title, Text, Divider, TextInput, Select, Group, Button, Loader } from '@mantine/core';
import { IconCalendarMonth } from '@tabler/icons-react';
import { DateInput } from '@mantine/dates';
import { useBrandSettings } from '../../BrandContext';
import { useMedplum } from '@medplum/react';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const EditPatientProfile: React.FC = () => {
  const navigate = useNavigate();
  const brandDetails = useBrandSettings();
  const medplum = useMedplum();
  const profile = medplum.getProfile();
  const id = profile?.id;
  const [status, setStatus] = useState<boolean | undefined>(profile?.active);
  const [loading, setLoading] = useState(false);

  const form = useForm({
    initialValues: {
      name: '',
      email: '',
      dob: null as Date | null,
      gender: '',
      mobile: '',
    },
    validate: {
      name: (value) => (value.length < 2 ? 'Name must have at least 2 characters' : null),
      email: (value) => (/^\S+@\S+\.\S+$/.test(value || '') ? null : 'Invalid email'),
      dob: (value) => (value ? null : 'Date of birth is required'),
      gender: (value) => (value ? null : 'Gender is required'),
      mobile: (value) => (/^\d{10}$/.test(value) ? null : 'Mobile number must be 10 digits'),
    },
  });

  useEffect(() => {
    if (id) {
      setLoading(true); // Set loading to true when the request starts

      medplum
        .readResource('Patient', id)
        .then((patient) => {
          if (patient) {
            const nameDetails = patient?.name?.[0] || {};
            const lastName = nameDetails?.family || '';
            const firstName = nameDetails?.given?.[0] || '';
            const capitalizedFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
            const capitalizedLastName = lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();

            const fullName = `${capitalizedFirstName} ${capitalizedLastName}`.trim();
            setStatus(patient?.active !== undefined ? patient.active : true);
            form.setValues({
              name: fullName,
              email: patient?.telecom?.find((item) => item.system === 'email')?.value || '',
              dob: patient?.birthDate ? new Date(patient.birthDate) : null,
              gender: patient?.gender?.toLowerCase() || '',
              mobile: patient?.telecom?.find((item) => item.system === 'phone')?.value || '',
            });
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [id, medplum]);

  const handleSubmit = async (values: any) => {
    try {
      const existingPatient = await medplum.readResource('Patient', id as string);

      const formattedDob = values.dob ? new Date(values.dob).toISOString().split('T')[0] : null;

      const updatedPatient = {
        ...existingPatient,
        name: [
          {
            given: [values.name.split(' ')[0] || ''],
            family: values.name.split(' ')[1] || '',
          },
        ],
        birthDate: formattedDob,
        telecom: [
          { system: 'email', value: values.email || '' },
          { system: 'phone', value: values.mobile || '' },
        ],
        address: [
          {
            line: [values.street || ''],
            city: values.city || '',
            state: values.state || '',
            postalCode: values.postalCode || '',
          },
        ],
        gender: values.gender || '',
        active: values.status !== undefined ? values.status : existingPatient.active,
      };

      await medplum.updateResource(updatedPatient as any);

      // Show success notification
      showNotification({
        message: 'Patient information updated successfully!',
        color: 'green',
      });

      navigate(`/dashboard`);
    } catch (error) {
      console.error('Error updating patient:', error);

      // Show failure notification
      showNotification({
        message: 'Failed to update patient information. Please try again.',
        color: 'red',
      });
    }
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Container className="tw-mt-5 tw-ml-5">
        <Text className="tw-text-[#101828] tw-font-semibold tw-text-xl tw-font-['Inter']">Profile</Text>

        <Text className="tw-text-[#475467] tw-text-sm font-normal tw-mt-1">Update your personal details here</Text>

        <Divider className="tw-mt-5 tw-flex-2" />
        <Title className="tw-text-[#101828] tw-font-semibold tw-text-lg tw-mt-5 tw-mb-5">Basic Information</Title>

        <Divider />
        {loading ? (
          <div className="tw-flex tw-justify-center tw-mt-10">
            <Loader size="xl" className="w-10 h-12" />
          </div>
        ) : (
          <>
            <div className="tw-flex tw-mt-5">
              <Text className="tw-flex-shrink-0 tw-mr-1 tw-text-[#344054] tw-font-medium tw-text-[14px] tw-font-['Inter'] tw-w-40">
                Name
              </Text>
              <TextInput
                placeholder="Enter Name"
                required
                className="tw-mb-1 tw-ml-16 tw-w-[400px] tw-bg-white  tw-rounded"
                {...form.getInputProps('name')}
              />
            </div>

            <div className="tw-flex tw-mt-5">
              <Text className="tw-flex-shrink-0 tw-mr-1 tw-text-[#344054] tw-font-medium tw-text-[14px] tw-font-['Inter'] tw-w-40">
                Email Address
              </Text>
              <TextInput
                placeholder="Enter Email"
                required
                className="tw-mb-1 tw-ml-16 tw-w-[400px] tw-bg-white  tw-rounded"
                {...form.getInputProps('email')}
              />
            </div>

            <div className="tw-flex tw-mt-5">
              <Text className="tw-flex-shrink-0 tw-mr-1 tw-text-[#344054] tw-font-medium tw-text-[14px] tw-font-['Inter'] tw-w-40">
                Date of Birth
              </Text>
              <DateInput
                placeholder="yyyy-mm-dd"
                valueFormat="YYYY-MM-DD"
                name="dob"
                rightSection={<IconCalendarMonth size="16px" />}
                {...form.getInputProps('dob', { required: true })}
                className="tw-mb-1 tw-ml-16 tw-w-[400px] tw-bg-white tw-rounded"
              />
            </div>

            <div className="tw-flex tw-mt-5">
              <Text className="tw-flex-shrink-0 tw-mr-1 tw-text-[#344054] tw-font-medium tw-text-[14px] tw-font-['Inter'] tw-w-40">
                Gender
              </Text>
              <Select
                placeholder="Select Gender"
                data={[
                  { value: 'male', label: 'Male' },
                  { value: 'female', label: 'Female' },
                  { value: 'other', label: 'Other' },
                ]}
                className="tw-mb-1 tw-ml-16 tw-w-[400px] tw-bg-white  tw-rounded"
                {...form.getInputProps('gender', { required: true })}
              />
            </div>

            <div className="tw-flex tw-mt-5">
              <Text className="tw-flex-shrink-0 tw-mr-1 tw-text-[#344054] tw-font-medium tw-text-[14px] tw-font-['Inter'] tw-w-40">
                Mobile Number
              </Text>
              <TextInput
                type="tel"
                placeholder="Enter phone number"
                required
                maxLength={10}
                className="tw-mb-1 tw-ml-16 tw-w-[400px] tw-bg-white  tw-rounded"
                {...form.getInputProps('mobile')}
              />
            </div>

            <div className="tw-flex tw-mt-5">
              <Text className="tw-flex-shrink-0 tw-mr-1 tw-text-[#344054] tw-font-medium tw-text-[14px] tw-font-['Inter'] tw-w-40">
                Account Status
              </Text>
              <div
                className={`tw-mb-1 tw-ml-16 tw-w-[100px] tw-font-medium tw-text-sm tw-px-4 tw-py-1 tw-rounded-full tw-text-center tw-border tw-flex tw-items-center ${
                  status
                    ? 'tw-bg-[#ECFDF3] tw-text-[#067647] tw-border-[#ABEFC6]'
                    : 'tw-bg-[#FEE4E2] tw-text-[#B42318] tw-border-[#FDA29B]'
                }`}
              >
                <span
                  className={`tw-h-1.5 tw-w-1.5 tw-rounded-full tw-mr-2 ${status ? 'tw-bg-[#17B26A]' : 'tw-bg-[#B42318]'}`}
                ></span>
                {status ? 'Active' : 'Inactive'}
              </div>
            </div>

            <Divider className="tw-mt-8 tw-flex-2" />
            <Group align="right" className="tw-flex tw-justify-end tw-mt-8">
              <Button
                variant="outline"
                color="gray"
                onClick={() => navigate(`/dashboard`)}
                className="tw-mr-2 tw-text-[#344054] tw-font-medium tw-text-sm tw-font-['Inter'] tw-rounded-lg"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className={`tw-bg-[${brandDetails?.backgroundColor}] tw-text-white tw-font-medium tw-text-sm tw-font-['Inter'] tw-rounded-lg`}
                style={{ backgroundColor: brandDetails?.backgroundColor }}
              >
                Update
              </Button>
            </Group>
          </>
        )}
      </Container>
    </form>
  );
};
