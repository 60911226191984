/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { Bundle, Patient } from '@medplum/fhirtypes';

export const checkInputValue = (name: string, value: string) => {
  const nonAlphabeticFields = [
    'name',
    'email',
    'address',
    'phone',
    'npi',
    'brandMobile',
    'brandEmail',
    'password',
    'confirm_password',
    'postalCode',
    'providerMobile',
    'providerEmail',
    'street',
    'city',
    'state',
    'service',
    'firstName',
    'lastName',
    'summary',
    'first_name',
    'last_name',
    'suiteApt',
    'timezone',
    'insurance',
    'mobile',
    'insuranceNumber',
    'startTime',
    'endTime',
  ];
  const limitedLengthFields = ['phone', 'brandMobile', 'providerMobile', 'npi', 'postalCode', 'mobile'];
  const numericFields = [
    'phone',
    'brandMobile',
    'providerMobile',
    'npi',
    'postalCode',
    'price',
    'insuranceNumber',
    'mobile',
  ];

  const isAlphabetic = /^[a-zA-Z\s]*$/.test(value);
  const isNumeric = /^[0-9]*$/.test(value);

  if (!isAlphabetic && !nonAlphabeticFields.includes(name)) {
    return null; // Ignore non-alphabetic input
  }

  if (numericFields.includes(name) && !isNumeric) {
    return null; // Ignore non-numeric input for numeric fields
  }

  if (limitedLengthFields.includes(name) && value.length > 10) {
    return value.slice(0, 10);
  }

  return value;
};

export function getPage(offset: number): number {
  return Math.floor((offset ?? 0) / 10) + 1;
}

export function getTotalPages(offset: number, lastResult: Bundle): number {
  const pageSize = 10;
  const total = getTotal(offset, lastResult);
  return Math.ceil(total / pageSize);
}

export function getTotal(offset: number, lastResult: Bundle): number {
  if (!lastResult) {
    // If lastResult is undefined or null, return 0 as the total
    return 0;
  }
  let total = lastResult.total;
  if (total === undefined) {
    // If the total is not specified, then we have to estimate it
    total =
      (offset ?? 0) + (lastResult.entry?.length ?? 0) + (lastResult.link?.some((l) => l.relation === 'next') ? 1 : 0);
  }
  return total;
}

export const formatDate = (dateString: string | number | Date) => {
  if (!dateString) {
    return ''; // or return a default value like 'Invalid Date'
  }
  const date = new Date(dateString);
  const day = String(date?.getDate()).padStart(2, '0');
  const month = String(date?.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date?.getFullYear();
  return `${day}/${month}/${year}`;
};

export function formatPatientName(patient: Patient) {
  const name = patient?.name?.[0] || {};
  const firstName = name.given?.[0] ?? '';
  const lastName = name.family || '';
  const fullName = [firstName, lastName].filter(Boolean).join(' ');
  return fullName.replace(/\s+/g, ' ') || '-';
}
