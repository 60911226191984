/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { useBrandSettings } from '../../../app/src/BrandContext';
import { Tooltip } from '@mantine/core';

interface GroupButtonsProps {
  value: { id: number, name: string, icon?: React.ReactNode }[];
  buttonHandleClick: (data: { id: number, name: string }) => void;
  showTooltip?: boolean;
}

const GroupButtons = (props: GroupButtonsProps) => {
  const brandDetails = useBrandSettings();
  const [btnActive, setBtnActive] = React.useState<number>(props?.value?.[0].id);
  const handleClick = (data: { id: number, name: string }) => {
    setBtnActive(data.id);
    props.buttonHandleClick(data);
  }
  return (
    <>
      <div className="tw-inline-flex tw-rounded-md time-filter" role="group">
        {props?.value.map((data) => {
          const buttonStyle = {
            color: btnActive === data.id ? brandDetails?.secondaryColor : '#667085',
            border: `1px solid ${btnActive === data.id ? brandDetails?.secondaryColor : '#667085'}`,
            backgroundColor: btnActive === data.id ? brandDetails?.backgroundColor : '#fff',
          };

          const button = (
            <button
              type="button"
              className={
                !props.showTooltip
                  ? `tw-text-[white] tw-font-semibold tw-rounded-lg tw-text-sm tw-px-5 tw-py-2 tw-text-center tw-inline-flex tw-items-center tw-me-2 tw-mb-2 tw-border tw-border-[#D6BBFB] tw-w-max ${btnActive === data.id ? 'tw-bg-[#e1e3e796]' : 'tw-bg-white'
                  }`
                  : ''
              }
              onClick={() => handleClick(data)}
              style={!props.showTooltip ? buttonStyle : {}}
            >
              {/* Show only icon if showTooltip is true, else show name and icon */}
              {data.icon && !props.showTooltip && <span className="tw-mr-2">{data.icon}</span>}
              {!props.showTooltip && data.name}
              {data.icon && props.showTooltip &&
                <div className="tw-bg-[#F9F5FF] tw-p-2 tw-h-[55px] tw-w-[55px] tw-rounded-full" style={{ background: brandDetails?.secondaryColor, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div className="tw-bg-[#F4EBFF] tw-p-1.5 tw-h-[35px] tw-w-[35px] tw-rounded-full" style={{ background: brandDetails?.primaryColor, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {React.cloneElement(data.icon as React.ReactElement, { color: brandDetails?.iconColor || '#9552E8', size: 20 })}
                  </div>
                </div>
              }
            </button>
          );

          // If showTooltip is true, wrap button in Tooltip, else just render the button
          if (props.showTooltip) {
            return (
              <Tooltip key={data.id} label={data.name} withArrow>
                {button}
              </Tooltip>
            );
          } else {
            return button;
          }
        })}
      </div>
    </>
  )
}

export default GroupButtons;
