/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { createContext, useContext, ReactNode, useState } from 'react';

interface AppContextProps {
  isCheckPageRefresh: boolean;
  setIsCheckPageRefresh: (value: boolean) => void;
  conversationSessionId: string;
  setConversationSessionId: (value: string) => void;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isCheckPageRefresh, setIsCheckPageRefresh] = useState(false);
  const [conversationSessionId, setConversationSessionId] = useState('');

  return (
    <AppContext.Provider
      value={React.useMemo(
        () => ({
          isCheckPageRefresh,
          setIsCheckPageRefresh,
          conversationSessionId,
          setConversationSessionId,
        }),
        [isCheckPageRefresh, conversationSessionId]
      )}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};
