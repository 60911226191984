/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import GroupButtons from '../../components/GroupButtons';
import AssessmentInsights from './AssessmentInsights';
import PatientHealthSummary from './PatientHealthSummary';
import { useBrandSettings } from '../../BrandContext';
import { IconActivityHeartbeat, IconServerCog, IconInfoTriangle } from '@tabler/icons-react';
import { Tooltip } from '@mantine/core';
import { useAppContext } from '../../AppProvider';



const AIInsights = () => {
  const brandDetails = useBrandSettings();
  const [activeTab, setActiveTab] = useState<string>('AI Health Summary');
  const  { isCheckPageRefresh } = useAppContext()
  const buttonName: any = [
    {
      id: 1,
      name: activeTab === 'AI Health Summary' ? 'Recent Patient AI Surveys' : 'AI Health Summary',
      icon: activeTab === 'Recent Patient AI Surveys' ? <IconActivityHeartbeat /> : <IconServerCog />
    }
  ];

  // for checking page refresh
  useEffect(() => {
    setActiveTab(activeTab);
  }, [isCheckPageRefresh]);

  const buttonHandleClick = (data: { id: number, name: string }) => {
    setActiveTab(data.name);
  };

  const getTitle = () => {
    if (activeTab === 'AI Health Summary') {
      return 'AI Health Summary';
    } else if (activeTab === 'Recent Patient AI Surveys') {
      return 'Recent Patient AI Surveys';
    } else {
      return 'AI Insights';
    }
  };

  return (
    <>
      <div className='tw-mt-6'>
        <div className='tw-bg-[#FFFFFF] tw-p-2 tw-rounded-t-lg tw-py-3 tw-flex tw-justify-between tw-items-center tw-opacity-[1]' style={{ backgroundColor: brandDetails?.secondaryColor, border: `1px solid #EAECF0` }}>
          <div>
            <h5 className="tw-text-[18px] xs:tw-text-[18px] tw-font-semibold tw-tracking-tight tw-text-[#101828]">{getTitle()}</h5>
            <h6 className='tw-text-[#475467] tw-text-[14px]'>Insights on patient behavior to improve engagement and satisfaction</h6>
          </div>
          <div className='tw-flex tw-items-center'>
            <Tooltip
              label={
                <div style={{ width: '280px', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                  <strong style={{ fontSize: '14px' }}>Disclaimer</strong>
                  <div style={{ fontSize: '12px' }}>
                    For Informational Purposes only, all medical decisions need to be discussed and determined by you and your Healthcare Provider.
                  </div>
                </div>
              }
              withArrow
            >
              <div className="tw-bg-[#F9F5FF] tw-p-2 tw-h-[55px] tw-w-[55px] tw-rounded-full" style={{ background: brandDetails?.secondaryColor, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className="tw-bg-[#F4EBFF] tw-p-1.5 tw-h-[35px] tw-w-[35px] tw-rounded-full" style={{ background: brandDetails?.primaryColor, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <IconInfoTriangle color={brandDetails?.iconColor || '#9552E8'} size={20} />
                </div>
              </div>
            </Tooltip>
            <GroupButtons
              value={buttonName}
              buttonHandleClick={(data) => buttonHandleClick(data)}
              showTooltip={true}
            />
          </div>
        </div>
        {activeTab === 'Recent Patient AI Surveys' && (
          <AssessmentInsights />
        )}
        {activeTab === 'AI Health Summary' && (
          <PatientHealthSummary />
        )}
      </div>
    </>
  );
};

export default AIInsights;