/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-nested-ternary */
import { formatDate } from '@medplum/core';
import { IconEye } from '@tabler/icons-react';
import { Center, Container, Text, Tooltip } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { getPage, getTotalPages } from '../../utils/util';
import { useBrandSettings } from '../../BrandContext';

interface PatientInfoProps {
  activeTab: number;
  resourceData: any;
  isLoading: boolean;
  offsetValue: (offset: number) => void;
}

interface RowData {
  [key: string]: any;
}

export function PatientInfo({ activeTab, resourceData, isLoading, offsetValue }: PatientInfoProps): JSX.Element {
  const brandDetails = useBrandSettings();
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState<number>(0);
  // Define column configurations for each tab
  const tabColumns: Record<number, string[]> = {
    1: ['Report Name', 'Category', 'Performer', 'Date Issued On', 'Effective Date', 'Status'],
    2: ['Prescription Date', 'Medication Name', 'Dosage Instruction', 'Frequency', 'Status'],
    3: ['Invoice Date', 'Practitioner', 'Amount', 'Payment Status', 'Payment Method'],
  };

  const columns = tabColumns[activeTab] || [];

  function getCategoryName(resource: any): string {
    const categories =
      resource?.category?.map((category: { coding: { display: string }[] }) => category.coding?.[0]?.display) || [];
    return categories.join('');
  }

  useEffect(() => {
    setCurrentPage(1);
    setOffset(0);
    offsetValue(0);
  }, [activeTab]);

  const getResourceData = (resource: any): RowData => {
    switch (activeTab) {
      case 1: // Reports
        return {
          'Report Name': resource?.resource?.code?.text || resource?.resource?.code?.coding?.[0]?.display || '-',
          Category: getCategoryName(resource?.resource) || '-',
          Performer: resource?.resource?.performer ? resource?.resource?.performer[0]?.display : '-',
          Status: resource?.resource?.status,
          'Date Issued On': formatDate(resource?.resource?.issued) || '-',
          'Effective Date': formatDate(resource?.resource?.effectiveDateTime) || '-',
        };
      case 2: // Prescriptions
        return {
          'Prescription Date': formatDate(resource?.resource?.dispenseRequest?.validityPeriod?.start) || '-',
          'Medication Name':
            resource?.resource?.authorizingPrescription?.[0]?.display ||
            resource?.resource?.medicationCodeableConcept?.coding?.[0]?.display ||
            '-',
          'Dosage Instruction': resource.resource?.dosageInstruction?.[0]?.patientInstruction ? (
            <Tooltip label={resource.resource.dosageInstruction[0].patientInstruction} multiline w={220}>
              <span>
                {resource.resource.dosageInstruction[0].patientInstruction.slice(0, 20)}
                {resource.resource.dosageInstruction[0].patientInstruction.length > 20 && '...'}
              </span>
            </Tooltip>
          ) : (
            '-'
          ),
          Frequency: resource?.resource?.dispenseRequest?.numberOfRepeatsAllowed || '0',
          Status: resource.resource?.status || '-',
        };
      case 3: // Invoice
        return {
          'Invoice Date': formatDate(resource?.resource?.date) || '-',
          Practitioner: resource?.resource?.participant?.[0]?.actor.display || '-',
          Amount: resource?.resource?.totalGross?.value || '-',
          'Payment Status': resource?.resource?.status,
          'Payment Method': resource?.resource?.paymentTerms || '-',
        };
      default:
        return {} as Record<string, string>;
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setOffset((page - 1) * 10);
    offsetValue((page - 1) * 10);
  };

  return (
    <div className="tw-w-full tw-mt-3 tw-overflow-auto scrollable_div">
      {/* Table */}
      <div className="tw-bg-white ">
        <table className="tw-w-full tw-text-sm tw-text-left">
          <thead className="tw-text-xs tw-text-gray-700 tw-bg-gray-50 tw-border-b-[1px] tw-border-[#EAECF0]">
            <tr className="tw-py-3 tw-px-6">
              {columns.map((column, index) => (
                <th key={index} className="tw-py-3 tw-px-6 tw-text-[#475467] tw-font-medium tw-text-sm">
                  <div className="tw-flex tw-justify-between tw-items-center tw-w-max ">{column}</div>
                </th>
              ))}
              <th className="tw-py-3 tw-px-6 tw-text-[#475467] tw-font-medium "></th>
            </tr>
          </thead>

          <tbody>
            {!isLoading ? (
              resourceData?.entry?.map((resource: any, index: number) => {
                const rowData = getResourceData(resource);
                return (
                  <tr key={index} className="tw-border-b hover:tw-bg-gray-50 tw-cursor-pointer">
                    {columns.map((column, colIndex) => (
                      <td key={colIndex} className="tw-px-6 tw-py-4 tw-font-normal tw-text-[#475467]">
                        {column === 'Status' ? (
                          <div>
                            <span
                              className={`tw-border tw-rounded-lg tw-py-[2px] tw-px-2 tw-font-medium ${
                                resource.status === 'not-done'
                                  ? 'tw-border-[#FFC080] tw-text-[#8B4513] tw-bg-[#F7D2C4]'
                                  : 'tw-border-[#ABEFC6] tw-text-[#067647] tw-bg-[#ECFDF3]'
                              }`}
                            >
                              {resource.status ? resource.status : rowData[column]}
                            </span>
                          </div>
                        ) : (
                          rowData[column] || '-'
                        )}
                      </td>
                    ))}
                    {/* <td className="tw-px-6 tw-py-4">
                      <IconEye size={22} stroke={'1.67px'} color={brandDetails?.textColor || '#6941C6'} />
                    </td> */}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={columns.length + 1}>
                  <Container>
                    <Center style={{ height: 150 }}>
                      <Text size="xl" c="dimmed">
                        Loading...
                      </Text>
                    </Center>
                  </Container>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {!resourceData?.entry?.length && !isLoading && (
          <Container>
            <Center style={{ height: 150 }}>
              <Text size="xl" c="dimmed">
                No results
              </Text>
            </Center>
          </Container>
        )}
        {resourceData && resourceData.entry && resourceData.entry.length !== 0 && (
          <div className="tw-flex tw-justify-between tw-items-center tw-mt-4 tw-px-5 tw-pb-5">
            <button
              className={`tw-px-4 tw-py-2 tw-border tw-rounded-md ${
                currentPage === 1 ? 'tw-bg-gray-100 tw-text-gray-400' : 'tw-bg-white tw-text-black'
              }`}
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              &larr; Previous
            </button>
            <div className="tw-flex tw-items-center">
              {[...Array(Math.min(10, getTotalPages(offset, resourceData)))].map((_, index) => {
                const pageNumber = index + 1;
                return (
                  <button
                    key={index}
                    className={`tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-rounded-lg ${
                      index + 1 === getPage(offset)
                        ? 'tw-bg-[#F9FAFB] tw-text-gray-700'
                        : 'tw-text-gray-700 tw-bg-white'
                    }`}
                    onClick={() => handlePageChange(pageNumber)}
                  >
                    {index + 1}
                  </button>
                );
              })}
            </div>
            <button
              className={`tw-px-4 tw-py-2 tw-border tw-rounded-md ${
                currentPage === Math.ceil(resourceData.total / 10)
                  ? 'tw-bg-gray-100 tw-text-gray-400'
                  : 'tw-bg-white tw-text-black'
              }`}
              disabled={currentPage === Math.ceil(resourceData.total / 10)}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next &rarr;
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
